import React from 'react'
import Helmet from "react-helmet"
import Nav from './navigation'
import Footer from './footer'
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import ConditionalWrapper from './conditionalWrapper';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import { RouteComponentProps } from "@reach/router"

import * as styles from "../styles/layout.module.css";

interface LayoutProps extends RouteComponentProps {
    children: JSX.Element[] | JSX.Element;
    pageMeta: {
      title: string
    };
    background?: string | ImageDataLike
    lang: string
    defaultLang: string
    navbarBackground?:string
    extra_styles?: React.CSSProperties;
  }

export default function Layout({ children, pageMeta, background = "#101010", lang, defaultLang, location, navbarBackground, extra_styles}: LayoutProps) {
  const backgroundAsImage = (background as ImageDataLike);
  let bgImage: any;
  if (backgroundAsImage) {
    const image = getImage(backgroundAsImage)!
    bgImage = convertToBgImage(image);
  }

  return (
    <>
      <Helmet>
        <title>{`XLNT | ${pageMeta.title}`}</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <ConditionalWrapper condition={bgImage}
          wrapper_true={(children: JSX.Element): JSX.Element => (
            <BackgroundImage Tag="article" {...bgImage} className={styles.layout_background} preserveStackingContext>
                {children}
            </BackgroundImage>
          )}
          wrapper_false={(children: JSX.Element): JSX.Element => (
            <article className={styles.layout_background} style={{backgroundColor: (background as string)}} >
                {children}
            </article>
        )}
      >
        <>
          <Nav lang={lang} defaultLang={defaultLang} location={location} navbarBackground={navbarBackground}/>
          <main style={extra_styles}>
              {children}
          </main>
          <Footer lang={lang} defaultLang={defaultLang} location={location}/>
        </>
      </ConditionalWrapper>
    </>
  );
}