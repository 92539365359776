// extracted by mini-css-extract-plugin
export var footer_address = "footer-module--footer_address--IKCCg";
export var footer_center_content = "footer-module--footer_center_content--CdQ+O";
export var footer_center_text = "footer-module--footer_center_text--C3DBl";
export var footer_container = "footer-module--footer_container--I68P5";
export var footer_left_content = "footer-module--footer_left_content--ipSUV";
export var footer_nav_wrapper = "footer-module--footer_nav_wrapper--I3Wm9";
export var footer_navbar_element = "footer-module--footer_navbar_element--9z9IA";
export var footer_navbar_element_active = "footer-module--footer_navbar_element_active--k3qFx";
export var footer_newsletter = "footer-module--footer_newsletter--caRtO";
export var footer_newsletter_button = "footer-module--footer_newsletter_button--tJ716";
export var footer_pku = "footer-module--footer_pku--4o3bv";
export var footer_right_content = "footer-module--footer_right_content--t4jc7";
export var footer_socialmedia_icon = "footer-module--footer_socialmedia_icon--7-NMK";
export var footer_socialmedia_link = "footer-module--footer_socialmedia_link--RLotc";
export var footer_sponsor = "footer-module--footer_sponsor--Ic4Om";
export var footer_wrapper = "footer-module--footer_wrapper--hfKDa";