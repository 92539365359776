import { Link } from 'gatsby'
import React, { useState } from 'react'
import { Col, Container, Navbar, Row, Nav, Button, Modal, Form } from 'react-bootstrap'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { RouteComponentProps } from "@reach/router"

import * as styles from "../styles/footer.module.css"
import Icon from './icon'
import PKU from '../assets/pku.svg'

interface FooterProps extends RouteComponentProps {
    lang: string
    defaultLang: string
}

export default function Footer({ lang, defaultLang }: FooterProps) {

    const [state, setState] = useState({ EMAIL: "", LNAME: "", FNAME: "", result: "" });
    const [show, setShow] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.id]: event.target.value });
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        addToMailchimp(state.EMAIL, { FNAME: state.FNAME, LNAME: state.LNAME })
            .then((data) => {
                setState({ ...state, result: data.result })
            })
            .catch((error: Error) => {
            });
    };

    function createLink(to: string) {
        if (lang === defaultLang) {
            return `/${to}`;
        }
        else {
            return `/${lang}/${to}`
        }
    }

    return (
        <footer className={styles.footer_wrapper}>
            <Container className={styles.footer_container} fluid>
                <Row className={styles.footer_sponsor}>
                    <Col>
                        <div className={styles.footer_center_content}>
                            <PKU className={styles.footer_pku}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={3} className={styles.footer_left_content}>
                        <div className={styles.footer_nav_wrapper}>
                            <div>
                                <Link className={`hvr-underline-from-left ${styles.footer_navbar_element}`} activeClassName={styles.footer_navbar_element_active} to={createLink("national")}>{lang === "hu" ? "Produkciók" : "Artists"}</Link>
                            </div>
                            <div>
                                <Link className={`hvr-underline-from-left ${styles.footer_navbar_element}`} activeClassName={styles.footer_navbar_element_active} to={createLink("international")}>{lang === "hu" ? "International roster" : "International roster"}</Link>
                            </div>
                            <div>
                                <Link className={`hvr-underline-from-left ${styles.footer_navbar_element}`} activeClassName={styles.footer_navbar_element_active} to={createLink("xlnt-records")}>{lang === "hu" ? "xlnt Records" : "xlnt Records"}</Link>
                            </div>
                            <div>
                                <Link className={`hvr-underline-from-left ${styles.footer_navbar_element}`} activeClassName={styles.footer_navbar_element_active} to={createLink("references")}>{lang === "hu" ? "Referenciák" : "References"}</Link>
                            </div>
                            <div>
                                <Link className={`hvr-underline-from-left ${styles.footer_navbar_element}`} activeClassName={styles.footer_navbar_element_active} to={createLink("backline")}>{lang === "hu" ? "Backline" : "Backline"}</Link>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className={`flex-column ${styles.footer_center_content}`}>
                        <Row className={styles.footer_newsletter}>
                            <Button variant="outline-light" onClick={() => setShow(true)} size="lg">
                                {lang === "hu" ? "Feliratkozás a hírlevélre" : "Subscribe to the newsletter"}
                            </Button>
                            <Modal show={show} onHide={() => setShow(false)}>
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{lang === "hu" ? "Feliratkozás a hírlevélre" : "Subscribe to the newsletter"}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group controlId="EMAIL">
                                            <Form.Label>{lang === "hu" ? "Email cím" : "Email Address"}</Form.Label>
                                            <Form.Control type="email" onChange={handleChange} required />
                                        </Form.Group>
                                        <Form.Group controlId="LNAME">
                                            <Form.Label>{lang === "hu" ? "Vezetéknév" : "Last Name"}</Form.Label>
                                            <Form.Control type="text" onChange={handleChange} required />
                                        </Form.Group>
                                        <Form.Group controlId="FNAME">
                                            <Form.Label>{lang === "hu" ? "Keresztnéb" : "First Name"}</Form.Label>
                                            <Form.Control type="text" onChange={handleChange} required />
                                        </Form.Group>

                                        {state.result !== "" ?
                                            state.result === "error" ?
                                                lang === "hu" ? "Hiba történt a feliratkozás közben. Lehetséges, hogy már fel vagy iratkozva, vagy egyéb hálózati probléma lépett fel."
                                                    : "There was an error in the subscription process. You may have already been subscribed or there was an unexpected error."
                                                : lang === "hu" ? "Sikeresen feliratkoztál!" : "Successfully subscribed!"
                                            : ""}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setShow(false)}>
                                            {lang === "hu" ? "Bezárás" : "Close"}
                                        </Button>
                                        <Button variant="primary" type='submit'>
                                            {lang === "hu" ? "Feliratkozás" : "Subscribe"}
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal>
                        </Row>
                        <Row className={styles.footer_center_text}>
                            <p>
                                {lang === "hu" ? "Minden jog fenntartva" : "All rights reserved"}
                            </p>

                        </Row>
                        <Row className={styles.footer_center_text}>
                            <p>
                                {new Date().getFullYear()}
                            </p>
                        </Row>
                    </Col>
                    <Col xs={12} lg={3} className={styles.footer_right_content}>
                        <Row>
                            <address className={styles.footer_address}>
                                <Col>
                                    Podlovics Péter
                                </Col>
                                <Col>
                                    +36 30/933-0652
                                </Col>
                                <Col>
                                    peter@xlnt.hu
                                </Col>
                            </address>
                        </Row>
                        <Row>
                            <Col>
                                <a href='/' className={styles.footer_socialmedia_link}> <Icon icon="facebookIcon" className={styles.footer_socialmedia_icon} /> </a>
                                <a href='/' className={styles.footer_socialmedia_link}> <Icon icon="instagramIcon" className={styles.footer_socialmedia_icon} /> </a>
                                <a href='/' className={styles.footer_socialmedia_link}> <Icon icon="youtubeIcon" className={styles.footer_socialmedia_icon} /> </a>
                                <a href='/' className={styles.footer_socialmedia_link}> <Icon icon="spotifyIcon" className={styles.footer_socialmedia_icon} /> </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}