import React from "react"
import { Link } from "gatsby"
import { Navbar, Container, Nav } from "react-bootstrap"
import { RouteComponentProps } from "@reach/router"


import Icon from "./icon";

import * as styles from '../styles/navbar.module.css';

interface NavProps extends RouteComponentProps {
    lang: string
    defaultLang: string
    navbarBackground?: string
}

export default function Navigation({location, lang, defaultLang, navbarBackground = 'none' } : NavProps) {

    function createLink(to: string) {
        if (lang === defaultLang) {
            return `/${to}`;
        }
        else {
            return `/${lang}/${to}`
        }
    }

    function createLangSwitchLink(toLang: string) {
        if (toLang !== lang) {
            if (toLang === defaultLang) {
                return location!.pathname.replace("/" + lang + "/", "/")
            } else {
                return `/${toLang}${location!.pathname}`;
            }
        } else {
            return location!.pathname
        }
    }

    return (
        <header className={styles.navbar_custom} style={{backgroundColor: navbarBackground}}>
            <Navbar collapseOnSelect expand="xxl" variant="dark">
                <Container>
                    <Navbar.Brand href={createLink("")} className={styles.navbar_logo}> <Icon icon='wideLogo'/> </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarResponsive"/>
                    <Navbar.Collapse id="navbarResponsive">
                        <Nav className="ms-auto">
                            <Nav.Item>
                                <Link className={`hvr-underline-from-left ${styles.navbar_element}`} activeClassName={styles.navbar_element_active} to={createLink("national")}>{lang === "hu" ? "Produkciók" : "Artists"}</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link className={`hvr-underline-from-left ${styles.navbar_element}`} activeClassName={styles.navbar_element_active} to={createLink("international")}>{lang === "hu" ? "International roster" : "International roster"}</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link className={`hvr-underline-from-left ${styles.navbar_element}`} activeClassName={styles.navbar_element_active} to={createLink("xlnt-records")}>{lang === "hu" ? "xlnt Records" : "xlnt Records"}</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link className={`hvr-underline-from-left ${styles.navbar_element}`} activeClassName={styles.navbar_element_active} to={createLink("references")}>{lang === "hu" ? "Referenciák" : "References"}</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link className={`hvr-underline-from-left ${styles.navbar_element}`} activeClassName={styles.navbar_element_active} to={createLink("backline")}>{lang === "hu" ? "Backline" : "Backline"}</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <div className={styles.navbar_lang_wrapper}>
                                    <Link to={createLangSwitchLink('hu')} className={styles.navbar_lang} activeClassName={styles.navbar_lang_active}>
                                        hu
                                    </Link>
                                    <Link to={createLangSwitchLink('en')} className={styles.navbar_lang} activeClassName={styles.navbar_lang_active}>
                                        en
                                    </Link>
                                </div>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}